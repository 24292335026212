import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import './sms-form.scss'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'

const SmsForm: React.FC = () => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (e: { target: { value: any } }) => {
    let value = e.target.value.replace(/\D/g, '')

    // Remove non-numeric characters
    value = value.replace(/\D/g, '')

    setInputValue(value)
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    const formEle = document.querySelector('form')

    if (!formEle) {
      console.error('Form element not found')
      return
    }

    const formDatab = new FormData(formEle)

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbwX8tLrUwpsTHG-f6WLgM6_1uJ9gYwIBlpgJ1mJKpA4QibMsKLGYsunWibbb0MzGV2t/exec',
        {
          method: 'POST',
          body: formDatab,
        }
      )

      // This is to get your message from the App script
      const data = await response.text()

      // This is to alert your message from the App script to the user
      // eslint-disable-next-line no-alert
      alert(data)

      // This is to reload the page when the form is submitted
      setTimeout(() => {
        window.location.reload()
      }, 4000)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  return (
    <Layout
      showBreadcrumbs
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Send SMS | GetMega"
        description="Send SMS - GetMega"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />
      <Container>
        <div className="sms-form-wrap">
          <p>Enter your phone number</p>
          <form onSubmit={handleSubmit}>
            <input
              name="Number"
              type="text"
              pattern="\d*"
              value={inputValue}
              onChange={handleInputChange}
              maxLength={10}
              minLength={10}
              placeholder="Phone Number"
              required
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      </Container>
    </Layout>
  )
}

export default SmsForm
